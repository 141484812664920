<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Atualizar Avaliação">
      <form-exam-database action="edit"></form-exam-database>
    </base-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: {
      title: 'Atualizar Avaliação'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Banco de Avaliações',
        disabled: false,
        to: '/examdatabase/list'
      },
      {
        text: 'Atualizar Avaliação',
        disabled: true,
        to: '/examdatabase/edit'
      }
    ]
  })
}
</script>
